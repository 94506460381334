import '@babel/polyfill'
import Vue from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/common.less'
import '@/styles/common.h5.less'
import 'swiper/css/swiper.min.css'
import 'ant-design-vue/dist/antd.css'

// import 'core-js/stable'
// import 'regenerator-runtime/runtime'
Vue.config.productionTip = false
Vue.use(Antd)
new Vue(
    {
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
