<template>
    <div class="analysis-home">
        <div class="ah-header">
            <div class="ahh-logo">
                <!-- <img src="../assets/index/logo@2x.png"> -->
            </div>
            <div class="ahh-tel">
                <span class="ahht-img"><img src="../assets/index/icon_tell@2x.png"></span>
                <span class="ahht-text">0592-7798088</span>
                <span class="ahht-img"><img src="../assets/index/icon_kefu@2x.png"></span>
                <span class="ahht-text">400-618-2090</span>
                <span class="ahht-login"
                      @click="goLogin">登录</span>
            </div>
        </div>
        <div class="ah-bg bg1">
            <div class="ah-body">
                <div class="ahb-top">
                    <div class="">
                        <div class="ahbt-swiper swiper-container swiper-container-initialized swiper-container-horizontal">
                            <div class="swiper-wrapper">
                                <div v-for="item in bannerTextList"
                                     :key="item.name"
                                     class="swiper-slide">
                                    <div class="banner-item">
                                        <div class="ahbt-title"
                                             v-text="item.title">
                                            恒河沙——AI决策板
                                        </div>
                                        <div class="ahbt-des"
                                             v-text="item.des">
                                            用AI赋能医药 用数字洞悉流通
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="swiper-button-next"></div>
                            <div class="swiper-button-prev"></div> -->
                            <div class="swiper-pagination"></div>
                        </div>
                    </div>
                    <div class="ahbt-btns">
                        <div class="ahbtb-item"
                             @click="applyUser">
                            申请试用
                        </div>
                        <!-- btn2 -->
                        <div class="ahbtb-item btn2"
                             @click="processVisible = true">
                            服务开通流程
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ai-bg platform">
            <div class="ah-body">
                <div class="platform-title">
                    <div class="pt-title">
                        AI决策板
                    </div>
                    <div class="pt-des">
                        AI Board
                    </div>
                    <div class="pt-bg">
                        <div class="ptb-left" />
                    </div>
                </div>
                <div class="ai-box">
                    <div class="ab-des">AI决策板是以大数据与AI技术为核心的智能决策看板，涵盖数据分析、综合决策、智能推荐、产品及目录查询等强大功能 可基于海量行业信息，为药械企业高效处理、分析产品数据，挖掘数据价值，提供大数据整体解决方案</div>
                    <div class="ab-body">
                        <div class="abb-title">从敏捷分析到智能决策，构建企业决策大脑</div>
                        <div class="abb-flex">
                            <div class="abbf-item">
                                <div class="abi-img"><img src="../assets/index/icon_ai01.png"></div>
                                <div class="abi-text">数据接入</div>
                            </div>
                            <div class="abbf-item2">
                                <div class="abi-img2"><img src="../assets/index/icon_arrow.png"></div>
                            </div>
                            <div class="abbf-item">
                                <div class="abi-img"><img src="../assets/index/icon_ai02.png"></div>
                                <div class="abi-text">数据集成</div>
                            </div>
                            <div class="abbf-item2">
                                <div class="abi-img2"><img src="../assets/index/icon_arrow.png"></div>
                            </div>
                            <div class="abbf-item">
                                <div class="abi-img"><img src="../assets/index/icon_ai03.png"></div>
                                <div class="abi-text">数据分析</div>
                            </div>
                            <div class="abbf-item2">
                                <div class="abi-img2"><img src="../assets/index/icon_arrow.png"></div>
                            </div>
                            <div class="abbf-item">
                                <div class="abi-img"><img src="../assets/index/icon_ai04.png"></div>
                                <div class="abi-text">综合决策</div>
                            </div>
                            <div class="abbf-item2">
                                <div class="abi-img2"><img src="../assets/index/icon_arrow.png"></div>
                            </div>
                            <div class="abbf-item">
                                <div class="abi-img"><img src="../assets/index/icon_ai05.png"></div>
                                <div class="abi-text">智能推荐</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ah-bg platform">
            <div class="ah-body">
                <div class="platform-title">
                    <div class="pt-title">
                        平台价值
                    </div>
                    <div class="pt-des">
                        Platform value
                    </div>
                    <div class="pt-bg">
                        <div class="ptb-left" />
                    </div>
                </div>
                <div class="platform-box">
                    <div class="pb-item-out">
                        <div class="pb-item">
                            <img class="pbi-right-top"
                                 src="../assets/index/icon_process_nor@2x.png">
                            <img class="pbi-img"
                                 src="../assets/index/icon_recommendation_nor@2x.png">
                            <div class="pbi-title">
                                近百张分析报表，一键生成
                            </div>
                            <div class="pbi-des">
                                告别Excel传统数据分析方式，智能工具直接上手使用；帮助企业从重复、繁重、低效的工作中解放人手
                            </div>
                        </div>
                    </div>
                    <div class="pb-item-out">
                        <div class="pb-item">
                            <img class="pbi-right-top"
                                 src="../assets/index/icon_process_nor@2x.png">
                            <img class="pbi-img"
                                 src="../assets/index/icon_affirmance_nor@2x.png">
                            <div class="pbi-title">
                                6种多维分析方法，任意搭配
                            </div>
                            <div class="pbi-des">
                                全方位、多视角挖掘分析产品数据，探寻数字背后规律；帮助企业第一时间掌握产品销售情况与市场趋势
                            </div>
                        </div>
                    </div>
                    <div class="pb-item-out">
                        <div class="pb-item">
                            <img class="pbi-right-top"
                                 src="../assets/index/icon_process_nor@2x.png">
                            <img class="pbi-img"
                                 src="../assets/index/icon_analysis_nor@2x.png">
                            <div class="pbi-title">
                                强大AI智能推荐，想你未想
                            </div>
                            <div class="pbi-des">
                                基于AI深度学习、数据模型等，动态评估不同品种市场规模、终端覆盖，为企业产品销售提供方向性指导
                            </div>
                        </div>
                    </div>
                </div>
                <div class="platform-bottom">
                    <img src="../assets/index/img_data_bg@2x.png">
                </div>
            </div>
        </div>
        <div class="ah-bg platform core">
            <div class="ah-body">
                <div class="platform-title">
                    <div class="pt-title">
                        核心功能
                    </div>
                    <div class="pt-des">
                        Core function
                    </div>
                    <div class="pt-bg">
                        <div class="ptb-left" />
                    </div>
                </div>
                <div class="core-body">
                    <div class="cb-item">
                        <div class="cbi-left">
                            <div class="cbil-num">
                                <img src="../assets/index/img_number_01@2x.png">
                            </div>
                            <div class="cbil-title">
                                智能推荐
                            </div>
                            <div class="cbil-des">
                                Intelligent Recommendation
                            </div>
                            <div class="cbil-dot">
                                <img src="../assets/index/icon_process_nor@2x.png">
                            </div>
                            <div class="cbil-text">
                                基于大数据与AI算法，帮助企业迅速找到产品销售特点，为企业智能推荐产品、医疗机构、企业等，精准定位目标用户，使营销效果最大化
                            </div>
                            <div class="cbil-bottom">
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_01_nor@2x.png"><span>产品智能推荐</span>
                                </div>
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_02_nor@2x.png"><span>医疗机构智能推荐</span>
                                </div>
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_03_nor@2x.png"><span>智能推荐查询</span>
                                </div>
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_04_nor@2x.png"><span>实时查询</span>
                                </div>
                            </div>
                        </div>
                        <div class="cbi-right">
                            <img src="../assets/index/pic_01.png">
                        </div>
                    </div>
                    <div class="cb-item">
                        <div class="cbi-left">
                            <div class="cbil-num">
                                <img src="../assets/index/img_number_02@2x.png">
                            </div>
                            <div class="cbil-title">
                                数据分析
                            </div>
                            <div class="cbil-des">
                                Data analysis
                            </div>
                            <div class="cbil-dot">
                                <img src="../assets/index/icon_process_nor@2x.png">
                            </div>
                            <div class="cbil-text">
                                提供六种以上数据分析与挖掘方案，支持企业实时对数据可视化自助分析，将数据分析难度降低为零，最大化挖掘数据的价值，轻松上手专业分析
                            </div>
                            <div class="cbil-bottom">
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_05_nor@2x.png"><span>流向分析</span>
                                </div>
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_06_nor@2x.png"><span>趋势分析</span>
                                </div>
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_07_nor@2x.png"><span>排名分析</span>
                                </div>
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_08_nor@2x.png"><span>分布分析</span>
                                </div>
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_08_nor@2x.png"><span>对比分析</span>
                                </div>
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_08_nor@2x.png"><span>聚合分析</span>
                                </div>
                            </div>
                        </div>
                        <div class="cbi-right">
                            <img src="../assets/index/pic_02.png">
                        </div>
                    </div>
                    <div class="cb-item">
                        <div class="cbi-left">
                            <div class="cbil-num">
                                <img src="../assets/index/img_number_03@2x.png">
                            </div>
                            <div class="cbil-title">
                                综合决策
                            </div>
                            <div class="cbil-des">
                                The comprehensive decision-making
                            </div>
                            <div class="cbil-dot">
                                <img src="../assets/index/icon_process_nor@2x.png">
                            </div>
                            <div class="cbil-text">
                                提供智能工具，通过四大类、上百张数据分析报表，帮助企业对产品销售进行多维度自助分析，还可灵活配置决策看板，有效提升决策准确性
                            </div>
                            <div class="cbil-bottom">
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_09_nor@2x.png"><span>销售金额分析</span>
                                </div>
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_10_nor@2x.png"><span>销售量分析</span>
                                </div>
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_10_nor@2x.png"><span>产品分析</span>
                                </div>
                                <div class="cbilb-item">
                                    <img src="../assets/index/icon_10_nor@2x.png"><span>流向分析</span>
                                </div>
                            </div>
                        </div>
                        <div class="cbi-right">
                            <img src="../assets/index/pic_03.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="ah-bg platform core2">
      <div class="ah-body"></div>
    </div> -->
        <!-- h5专用 -->
        <!-- <div class="ah-bg platform core3">
      <div class="ah-body"></div>
    </div> -->
        <div class="ah-bg platform service">
            <div class="ah-body">
                <div class="platform-title">
                    <div class="pt-title">
                        平台服务
                    </div>
                    <div class="pt-des">
                        Platform services
                    </div>
                    <div class="pt-bg">
                        <div class="ptb-left" />
                    </div>
                </div>
                <div class="service-box">
                    <a-tabs>
                        <a-tab-pane v-for="(item, index) in tabList"
                                    :key="item.title + index"
                                    :tab="item.title">
                            <div class="sb-table">
                                <a-table :columns="columns"
                                         :data-source="item.data"
                                         bordered
                                         :pagination="false" />
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                    <div class="sb-btns">
                        <div class="sbb-item my-btn"
                             @click="applyUser">
                            申请试用
                        </div>
                        <!-- <div class="sbb-item my-btn sbbi-right"
                             @click="goDemo">
                            立即体验
                        </div> -->
                    </div>
                </div>
                <div class="s-bottom">
                    <div class="platform-title">
                        <div class="pt-title">
                            用户反馈
                        </div>
                        <div class="pt-des">
                            User feedback
                        </div>
                        <div class="pt-bg">
                            <div class="ptb-left" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ss-user">
            <div class="ssu-swiper swiper-container swiper-container-initialized swiper-container-horizontal">
                <div class="swiper-wrapper">
                    <div v-for="item in userList"
                         :key="item.icon"
                         class="swiper-slide">
                        <div class="ssu-body">
                            <div class="sub-top">
                                <div class="sbt-img">
                                    <img :src="item.icon">
                                </div>
                                <div class="sbt-title">
                                    <div class="sbtt-title"
                                         v-text="item.userName" />
                                    <div class="sbtt-des"
                                         v-text="item.position" />
                                </div>
                            </div>
                            <div class="sub-bottom"
                                 v-text="item.desc" />
                        </div>
                    </div>
                </div>
                <!-- <div class="swiper-button-next"></div>
              <div class="swiper-button-prev"></div>
              <div class="swiper-pagination"></div> -->
            </div>
        </div>
        <!-- pc公告底部   -->
        <div class="common-footer-container">
            <div class="common-footer">
                <div class="footer-container">
                    <!-- <div class="about-us">
            <div class="about-us-item">
                    <span class="item-span-title">关于我们</span>
                    <a href="http://hxmec.com/about-us.html#center-intro" target="_blank" class="item-span-text">中心介绍</a>
                    <a href="http://hxmec.com/about-us.html#join-us" target="_blank" class="item-span-text">招贤纳士</a>
                    <a href="http://hxmec.com/about-us.html#contact-us" target="_blank" class="item-span-text">联系我们</a>
            </div>
            <div class="about-us-item">
                    <span class="item-span-title">热门服务</span>
                    <a href="http://hxmec.com/notification-service.html" target="_blank" class="item-span-text">申报服务</a>
                    <a href="http://hxmec.com/ca-service.html" target="_blank" class="item-span-text">CA服务</a>
                    <a href="http://hxmec.com/person-center/plus-rules.html" target="_blank" class="item-span-text">增值服务</a>
            </div>
            <div class="about-us-item">
                    <span class="item-span-title">资讯中心</span>
                    <a href="http://hxmec.com/dynamic/info-center-dynamic.html" target="_blank" class="item-span-text">中心动态</a>
                    <a href="http://hxmec.com/dynamic/info-center-industry.html" target="_blank" class="item-span-text">行业热点</a>
            </div>
            <div class="contact-us">
                    <div class="contact-phone">
                        <div class="phone-number">
                            400-618-2090
                        </div>
                        <div class="contact-time">
                            咨询时间：周一到周五 9：00-18：00
                        </div>
                    </div>
                    <div class="wx-logo"></div>
            </div>
          </div> -->
                    <!-- <div class="friend-link">
                <span class="friend-link-title">友情链接：</span>
                <a href="http://www.nhsa.gov.cn/" class="friend-link-item" target="_blank">国家医疗保障局</a>
                <a href="http://ybj.fujian.gov.cn/" class="friend-link-item" target="_blank">福建省医疗保障局</a>
                <a href="http://ylbzj.hebei.gov.cn/" class="friend-link-item" target="_blank">河北省医疗保障局</a>
                <a href="http://ybj.shandong.gov.cn/" class="friend-link-item" target="_blank">山东省医疗保障局</a>
                <a href="http://www.smygzx.com.cn/" class="friend-link-item" target="_blank">三明市医疗保障局</a>
                <a href="https://sm.udplat.org/" class="friend-link-item" target="_blank">三明联盟官网</a>
                <a href="http://ylbzj.cq.gov.cn/" class="friend-link-item" target="_blank">重庆市医疗保障局</a>
                <a href="http://ylbzj.dezhou.gov.cn/" class="friend-link-item" target="_blank">德州市医疗保障局</a>
          </div> -->
                    <div class="copy-right">
                        <span class="span-text">
                            copyright © 2020
                        </span>
                        <a href="http://beian.miit.gov.cn"
                           target="_blank"
                           class="copy-right-skip">闽ICP备14009242号</a>
                        <a href="http://hxmec.com/images.html"
                           target="_blank"
                           class="copy-right-skip"
                           style="margin-left:20px;">增值电信业务经营许可证 闽B2-20200443</a>
                        <a href="http://hxmec.com/images2.html"
                           target="_blank"
                           class="copy-right-skip"
                           style="margin-left:20px;">互联网药品信息服务资格证书 （闽）-非经营性-2018-0055</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- h5底部 -->
        <div class="com-footer">
            <!-- <div class="top-menu">
        <div class="tm-item">
            <div class="tmi-title" @click="toggleBottom(1)">关于我们<div class="tmit-icon"><DownOutlined v-if="!botMenu[1]" /><UpOutlined v-if="botMenu[1]" /></div></div>
            <div class="tmi-hide bottom-menu-1" :class="{'active':botMenu[1]}">
                <div class="tmi-des" @click="goPage('/about-us.html#center-intro')">中心介绍</div>
                <div class="tmi-des" @click="goPage('/about-us.html#join-us')">招贤纳士</div>
                <div class="tmi-des" @click="goPage('/about-us.html#contact-us')">联系我们</div>
            </div>
        </div>
        <div class="tm-item">
            <div class="tmi-title" @click="toggleBottom(2)">热门服务<div class="tmit-icon"><DownOutlined v-if="!botMenu[2]" /><UpOutlined v-if="botMenu[2]" /></div></div>
            <div class="tmi-hide bottom-menu-2" :class="{'active':botMenu[2]}">
                <div class="tmi-des" @click="goPage('/notification-service.html')">申报服务</div>
                <div class="tmi-des" @click="goPage('/ca-service.html')">CA服务</div>
                <div class="tmi-des" @click="goPage('/vip-rules.html')">增值服务</div>
            </div>
        </div>
        <div class="tm-item">
            <div class="tmi-title" @click="toggleBottom(3)">资讯中心<div class="tmit-icon"><DownOutlined v-if="!botMenu[3]" /><UpOutlined v-if="botMenu[3]" /></div></div>
            <div class="tmi-hide bottom-menu-3" :class="{'active':botMenu[3]}">
                <div class="tmi-des" @click="goPage('/dynamic/info-center-dynamic.html')">中心动态</div>
                <div class="tmi-des" @click="goPage('/dynamic/info-center-industry.html')">行业热点</div>
            </div>
        </div>
      </div> -->
            <div class="bottom-text">
                <!-- <div class="cf-bt-qr">
            <div class="cfbt-img">
                <img class="cfbti-img" src="../assets/qr@2x.png" />
            </div>
            <div class="cfbt-title">
                <div class="title">400-618-2090</div>
                <div class="des">咨询时间：周一至周五 9:00 -18:00</div>
            </div>
        </div> -->
                <div class="bottom-line">
                    增值电信业务经营许可证 闽B2-20200443
                </div>
                <div class="bottom-line">
                    互联网药品信息服务资格证书（闽）-非经营性-2018-0055
                </div>
                <div class="bottom-line">
                    闽ICP备14009242号
                </div>
                <div class="bottom-line">
                    copyright © 2020
                </div>
            </div>
        </div>
        <a-modal ref="formModal"
                 v-model="visible"
                 title="申请试用"
                 width="600px"
                 :footer="null"
                 :confirm-loading="confirmLoading"
                 @ok="handleOk">
            <div>
                <a-form-model ref="ruleForm"
                              :model="form"
                              :rules="rules"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol">
                    <a-form-model-item ref="userName"
                                       label="您的姓名："
                                       prop="userName">
                        <a-input v-model="form.userName" />
                    </a-form-model-item>
                    <a-form-model-item ref="tele"
                                       label="联系电话："
                                       prop="tele">
                        <a-input v-model="form.tele" />
                    </a-form-model-item>
                    <a-form-model-item ref="orgName"
                                       label="企业名称："
                                       prop="orgName">
                        <a-input v-model="form.orgName" />
                    </a-form-model-item>
                    <div class="dia-text">
                        提交成功后，恒河沙客户服务专员会第一时间与您取得联系，如有任何问题，请咨询400-618-2090
                    </div>
                    <a-form-item :wrapper-col="{ span: 24, offset: 9 }">
                        <a-button type="primary"
                                  @click="onSubmit">
                            提交
                        </a-button>
                        <a-button style="margin-left: 10px;"
                                  @click="visible = false">
                            取消
                        </a-button>
                    </a-form-item>
                </a-form-model>
            </div>
        </a-modal>
        <a-modal ref="formModal"
                 v-model="processVisible"
                 title="服务开通流程"
                 width="620px"
                 :footer="null"
                 :confirm-loading="confirmLoading">
            <div>
                <div style="font-size: 16px;">为保证用户数据的安全性、隐私性、保密性，防止用户的数据泄露。当用户与平台达成合作后，具体服务由数据智能部统一安排，具体流程如下：</div>
                <div style="padding: 20px 0;">
                    <a-steps v-model="current"
                        status="wait"
                             direction="vertical">
                        <a-step title="联系数据智能部">
                            <div slot="description">
                                <div style="font-size: 16px;">（1）手机176-0085-5006 ；电话：0592-7798088</div>
                                <div style="font-size: 16px;">（2）线上QQ、或微信沟通，扫描如下二维码：</div>
                                <div style="margin-left: 30px;"><img style="width: 80%;" src="../assets/qrcode.png"></div>
                            </div>
                        </a-step>
                        <a-step title="需求沟通"
                                description="基于合作的模块提供需要开通的相关需求。" />
                         <a-step title="需求评估"
                                description="评估开通需求所需求工作量，并与用户确认交付时间。" />
                                 <a-step title="服务交付"
                                description="服务开通后，会主动联系用户线上使用，为保证数据安全性，统一采用用户指定“手机号”+动态验证码，或CA登录平台。" />
                                 <a-step title="平台培训"
                                description="我们为合作用户免费提供平台使用培训（如客户需要），培训时统一采用演示环境（Demo）,避免培训讲师接触用户的数据。" />
                                 <a-step title="问题反馈"
                                description="若用户在使用过程中遇到任何问题，可通过以上方式联系，数据智能部会在第一时间反馈，并解决问题。" />

                    </a-steps>
                </div>
                <div style="text-align: center;">
                    <a-button style="margin-left: 10px;"
                              @click="processVisible = false">
                        确定
                    </a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
import Swiper from 'swiper'
import Axios from 'axios'

export default {
    name: 'Home',
    components: {
        // HelloWorld
    },
    data () {
        return {
            processVisible: false,
            current: 0,
            visible: false,
            confirmLoading: false,
            columns: [
                {
                    title: '模块',
                    dataIndex: 'modal',
                    key: 'modal'
                },
                {
                    title: '功能描述',
                    dataIndex: 'desc',
                    key: 'desc'
                }
            ],
            tabList: [
                {
                    title: '综合决策',
                    data: [
                        {
                            key: 't1',
                            modal: '销售金额分析',
                            desc:
                                '帮助药企及时了解产品销售金额的情况，为企业制定销售策略、市场开拓等提供决策支持：支持按片区、时间、产品、医疗机构、配送情况等维度，分析企业产品的销售总金额及其增长率、占比、排名、分布等。'
                        },
                        {
                            key: 't2',
                            modal: '销售量分析',
                            desc:
                                '帮助药企及时了解产品销售量的情况，为企业做好生产安排、销售管理、供应保障等提供决策支持：支持按片区、时间、产品、医疗机构、配送情况等维度，分析企业产品的销售总量及其增长率、占比、排名、分布等。'
                        },
                        {
                            key: 't3',
                            modal: '产品分析',
                            desc:
                                '帮助药企清晰地了解自家产品分布规律，为制定调整产品竞争策略提供决策支持：支持按地区、采购模式等维度，统计公司产品的数量、各品种贡献占比、片区排名等。'
                        },
                        {
                            key: 't4',
                            modal: '流向分析',
                            desc:
                                '帮助药企深入地了解产品配送及流向情况，为企业的供应链管理、配送效率优化提供决策支持：支持按产品分类、配送企业、配送片区、医疗机构性质及等级等维度，进行端到端的分析。'
                        }
                    ]
                },
                {
                    title: '数据分析',
                    data: [
                        {
                            modal: '聚合分析',
                            desc:
                                '提供药企各时间段内的销售汇总情况分析：支持按时间、片区、品种等维度，对企业销售金额、销售量、医疗机构数量、产品数量等指标进行汇总分析。'
                        },
                        {
                            modal: '趋势分析',
                            desc:
                                '提供药企产品的销售趋势变化分析：支持按时间、片区、医疗机构性质及等级、品种等多个维度，分析产品销售量、销售金额、医疗机构覆盖等变化趋势。'
                        },
                        {
                            modal: '流向分析',
                            desc:
                                '提供药企自家产品的流向情况分析：支持按品种、片区、配送企业、医疗机构性质及等级等多个维度，进行销售金额、销售量、产品、医疗机构等流向分析。'
                        },
                        {
                            modal: '排名分析',
                            desc:
                                '支持药企查询自有产品的片区竞争力情况：支持按时间、片区、医疗机构性质及等级、挂网模式等多个维度，进行产品销售量、销售金额等排名分析。'
                        },
                        {
                            modal: '对比分析',
                            desc:
                                '帮助药企对比分析自有产品竞争力：支持按时间、片区、医疗机构性质及等级、品种等多个维度，进行销售金额、销售量等对比分析。'
                        },
                        {
                            modal: '分布分析',
                            desc:
                                '支持药企掌握重点片区、重点品种的销售情况：支持按医疗机构、片区、产品、挂网模式等多个维度，进行产品销售金额、销售量等占比分布分析等。'
                        }
                    ]
                },
                {
                    title: '智能推荐',
                    data: [
                        {
                            modal: '企业产品智能推荐',
                            desc:
                                '基于药企产品品种的“采购金额”与“采购量”，通过AI智能推荐模型，提供该品种区域可供开拓规模情况，为企业产品销售计划提供参考。'
                        },
                        {
                            modal: '医疗机构智能推荐',
                            desc:
                                '基于药企各品种区域可开拓规模情况，通过AI智能推荐模型，进行相关医疗机构的排名、覆盖率推荐，为企业制定终端销售计划提供参考。'
                        },
                        {
                            modal: '智能推荐查询',
                            desc:
                                '按品种名称、片区、医疗机构性质与等级、智能推荐指数等，为药企提供智能查询功能，为企业综合决策提供参考。'
                        }
                    ]
                },
                {
                    title: '我关注的看板',
                    data: [
                        {
                            modal: '自定义决策',
                            desc:
                                '支持药企基于销售金额、销售量、产品数量、医疗机构数量等指标，结合片区、时间、产品、医疗机构类别与等级等维度，进行报表自动配置与编排。'
                        },
                        {
                            modal: '自定义分析',
                            desc:
                                '基于聚合分析、趋势分析、流向分析、排名分析、对比分析与分布分析等，自定义编排数据分析报表，也可基于系统报表进行自定义配置。'
                        }
                    ]
                },
                {
                    title: '目录产品查询',
                    data: [
                        {
                            modal: '目录查询',
                            desc:
                                '提供基础药物目录、医保药品目录、一致性评价目录、辅助药品目录、麻精药品目录、OTC药品目录等查询功能。'
                        },
                        {
                            modal: '产品查询',
                            desc: '提供药监注册产品、中西药产品、国药产品YPID、国家统一编码信息等查询功能。'
                        }
                    ]
                },
                {
                    title: '一键导出',
                    data: [
                        {
                            modal: '数据下载',
                            desc:
                                '所有分析报表、分析看板中的数据，均支持Excel文件下载，一键导出分析后的结果，方便企业取用。'
                        }
                    ]
                }
            ],
            userList: [
                {
                    icon: require('@/assets/index/head1.png'),
                    userName: '某集团公司',
                    position: '总经理',
                    desc:
                        '“这个平台提供的各个品种AI推荐指数，为我们公司制订销售、市场推广计划提供了不可或缺的支撑，让我们越来越信任AI学习算法的奇妙。”'
                },
                {
                    icon: require('@/assets/index/head2.png'),
                    userName: '某股份公司销售部',
                    position: '总经理',
                    desc:
                        '“平台提供的分析工具智能而实用，我们可借此轻松对销售数据进行专业分析，随时随地秒变专业数据分析师，让我们从繁琐的数据清理工作中解放了双手。”'
                },
                {
                    icon: require('@/assets/index/head3.png'),
                    userName: '某公司市场部',
                    position: '总经理',
                    desc:
                        '“我们已离不开这个便捷友好的平台了。一键授权导入数据后，企业可以及时查看及分析当前的销售情况，并可配置自己想要的报表，还能一键导出分析后的数据。”'
                },
                {
                    icon: require('@/assets/index/head4.png'),
                    userName: '某集团公司',
                    position: '销售部总监',
                    desc:
                        '“大数据时代，我们比较关注对数据挖掘与分析，这个平台满足我们的需求，通过对数据分析与挖掘，找出数据背后的规律，支持我们的决策。”'
                }
            ],
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
            other: '',
            form: {
                orgName: '',
                tele: '',
                userName: '',
                terminalType: 1 // 终端类型：1-PC，2-移动端
            },
            rules: {
                userName: [
                    { required: true, message: '请输入', trigger: 'blur' },
                    { min: 2, max: 30, message: '2到30个字符', trigger: 'blur' }
                ],
                tele: [
                    { required: true, message: '请输入', trigger: 'blur' },
                    { min: 11, max: 11, message: '11位电话号码', trigger: 'blur' }
                ],
                orgName: [
                    { required: true, message: '请输入', trigger: 'blur' },
                    { min: 2, max: 30, message: '2到30个字符', trigger: 'blur' }
                ]
            },
            botMenu: {
                1: false,
                2: false,
                3: false
            },
            bannerTextList: [
                {
                    name: 'btl1',
                    title: '恒河沙-AI决策板',
                    des: '用AI赋能医药 用数字洞悉流通'
                },
                {
                    name: 'btl2',
                    title: '敏捷分析-多维数据可视化 ',
                    des: '免费开通 立即使用'
                },
                {
                    name: 'btl3',
                    title: '智能决策-洞悉市场新机遇 ',
                    des: '免费开通 立即使用'
                }
            ]
        }
    },
    mounted () {
        const bannerS = new Swiper('.ahbt-swiper', {
            autoplay: true,
            // initialSlide: 2,
            // slidesPerView: 3,
            // slidesPerView: 'auto',
            // loopedSlides: 5,
            loop: true,
            // loopedSlides: 8,
            loopAdditionalSlides: 13,
            width: document.body.clientWidth > 1199 ? 900 : document.clientWidth,
            // effect: 'coverflow',
            speed: 300,
            // navigation:
            // {
            //   nextEl: '.swiper-button-next',
            //   prevEl: '.swiper-button-prev'
            // }
            pagination: {
                el: '.swiper-pagination'
            }
        })
        console.log(bannerS)
        // 在页面全都加载完成后进行初始化
        // swiper提供了use方法，其他写法没有啥区别和swiper4、5一样
        // Swiper.use([Autoplay])
        const mySwiper = new Swiper('.ssu-swiper', {
            autoplay: true,
            // initialSlide: 2,
            // slidesPerView: 3,
            // slidesPerView: 'auto',
            // loopedSlides: 5,
            loop: true,
            slidesPerView: document.body.clientWidth > 1199 ? 3 : 2,
            spaceBetween: 20,
            centeredSlides: document.body.clientWidth > 1199,
            // loopedSlides: 8,
            loopAdditionalSlides: 13,
            // width: document.body.clientWidth > 1199 ? 474 : 273,
            // effect: 'coverflow',
            speed: 300
            // navigation:
            // {
            //   nextEl: '.swiper-button-next',
            //   prevEl: '.swiper-button-prev'
            // }
            // pagination:
            // {
            //   el: '.swiper-pagination'
            // }
        })
        console.log(mySwiper)
    },
    methods: {
        goLogin () {
            window.location.href = 'https://gsai.hxmec.com/'
        },
        callback (key) {
            console.log(key)
        },
        goDemo () {
            window.open('http://gsdemo.hxmec.com/login')
        },
        applyUser () {
            this.visible = true
        },
        handleOk () {
            this.visible = false
        },
        onSubmit () {
            this.$refs.ruleForm.validate().then((type) => {
                console.log(type)
                if (type) {
                    const data = this.form
                    data.terminalType = 1
                    // Axios.post('/code/daw/trialInfo/save'
                    Axios.post('//gsdemo.hxmec.com/code/daw/trialInfo/save', data).then((response) => {
                        if (response.status === 200 && response.data.code === 0) {
                            this.visible = false
                            this.$refs.ruleForm.resetFields()
                            this.$message.success('提交成功')
                        } else {
                            this.$message.error(response.data.msg)
                        }
                    })
                }
            })
        },
        resetForm () {
            this.$refs.ruleForm.resetFields()
        },
        toggleBottom (type) {
            // this.botMenu[1] = false
            // this.botMenu[2] = false
            // this.botMenu[3] = false
            this.botMenu[type] = !this.botMenu[type]
        },
        goPage (url) {
            window.open(`http://hxmec.com/${url}`)
        }
    }
}
</script>
